import { FetchDiagnosticAnswersResponse } from "../commonSrc/constant/type";
import { ExistingDiagnosticAnswerFetcher } from "./ExistingDiagnosticAnswerFetcher";
import { getLogger } from '../log/getLogger'
import { CurrentPreviousNeverCodeEnum } from "../commonSrc/constant/currentPreviousNeverConstant";
import { UpTo3PlusTimesPerWeekCodeEnum } from "../commonSrc/constant/upToThreePlusTimesPerWeekConstant";
import { injectable } from "inversify";
import { WhoAmISvc } from "../auth/WhoAmISvc";
import diContainer from '../inversify.config'
import DI_TYPES from "../diTypes";
import { d4l } from "../commonSrc/log/logUtil";

@injectable()
export class ExistingDiagnosticAnswerFetcherRealImpl implements ExistingDiagnosticAnswerFetcher {
  private static readonly LOG = getLogger('ExistingDiagnosticAnswerFetcherRealImpl')
  private readonly LOG = ExistingDiagnosticAnswerFetcherRealImpl.LOG

  
  public fetchExistingAnswers(userId: string /* canonical UUID */): Promise<FetchDiagnosticAnswersResponse> {
    this.LOG.debug(`fetchExistingAnswers(): Entering with userId = ${userId}`)

    const whoAmISvc: WhoAmISvc = diContainer.get<WhoAmISvc>(DI_TYPES.WhoAmISvc)
    const loggedInFlag: boolean = whoAmISvc.amILoggedIn()
    const oauth2Username: string | undefined = whoAmISvc.getMyUsername()
    const oauth2UserId: string | undefined = whoAmISvc.getMyUserId()

    const headers: any = {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
    }

    if (whoAmISvc.amILoggedIn()) {
      this.LOG.debug(`fetchExistingAnswers(): User is signed in.  jwtToken = ${whoAmISvc.getMyAuthToken()}`)
      headers["Authorization"] = `Bearer ${whoAmISvc.defGetMyAuthToken()}`
    } else {
      this.LOG.warn(`fetchExistingAnswers(): Unauthorized 401.  User is NOT signed in.`)
    }

    const requestOptions = {
      method: 'GET',
      // mode: 'cors',
      headers: headers
    };

    const url = (process.env.REACT_APP_BACKEND_BASE_URL || 'http://localhost:9000') + `${process.env.REACT_APP_BACKEND_RELATIVE_API_PATH}` + '/questionnaire'

    if (!url.match(/9443/)) {
      this.LOG.error(`fetchExistingAnswers(): URL should have port 8443 in it.  Why doesn't it?   url = ${d4l(url)}`)
    }

    return fetch(url, requestOptions).then(response => {
      this.LOG.debug(`fetchExistingAnswers(): Wait for it...`)
      const retval = response.json()
      this.LOG.info(`fetchExistingAnswers(): Returning retval = ${d4l(retval)}`)
      return retval
    })
  }
}