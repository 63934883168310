import React, { useState, useEffect } from 'react';

import { SignupLoginHintCaptureFormComponent } from "../auth/SignupLoginHintCaptureFormComponent"
import { DeveloperAuthDetailComponent } from '../developer/DeveloperAuthDetailComponent'
import { getLogger } from '../log/getLogger'
import diContainer from '../inversify.config'
import DI_TYPES from '../diTypes'
import { WhoAmISvc } from '../auth/WhoAmISvc'
import { Link } from 'react-router-dom'
import * as Rx from "rxjs";
import { fetchExistingDiagnosticAnswers } from '../questionnaire/questionnaireSlice'
import { fetchExistingUserAttributes } from '../user/userAttributesSlice'
import store from '../store'
import { CombinedState, Dispatch } from 'redux'
import { setSignature, selectSignature } from '../user/userAttributesSlice'
import { setUserSpecifiedSignatureDate, selectUserSpecifiedSignatureDate } from '../user/userAttributesSlice'
import { useDispatch, useSelector } from 'react-redux';
import { SbhcCompositeState } from '../state/SbhcCompositeState';
import * as stringUtil from "../commonSrc/string/stringUtil"
import { useNavigate } from "react-router";
import RedirectAsNeededComponent from '../RedirectAsNeededComponent';
import { d4l } from '@jgithub/ts-gist-pile';

const LOG = getLogger('Homepage.tsx');
const whoAmISvc: WhoAmISvc = diContainer.get<WhoAmISvc>(DI_TYPES.WhoAmISvc);

// const currentSignatureInState: string = useSelector((state: SbhcCompositeState) => {
//   return selectSignature(state)
// })

// const currentUserSpecifiedSignatureDate: string = useSelector((state: SbhcCompositeState) => {
//   return selectUserSpecifiedSignatureDate(state)
// })

export function HomepageEmpty () {
  return(
    <>
    Hiha
    </>
  )
}

const scrollToRegistrationBlock = () => {
  const registrationBlock = document.querySelector( '#registration-block' );
  registrationBlock!.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  return false;
};

const scrollToFaq = () => {
  const element = document.querySelector( '#faq' );
  element!.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  return false;
};

export function Homepage () {
  const [homepageStillLoading, setHomepageStillLoading] = useState(true);


  const navigate = useNavigate();

  // useEffect(() ={
  //   window.location.href = "/questionnaire" as string  
  // }, []);
  const [getter_UNUSED, setForcedRerenderAt] = useState('');

  const observable: Rx.Observable<void> = whoAmISvc.getStateChangeObservable()
  let subscription: Rx.Subscription;
  // const currentSignatureInState: string = useSelector((state: SbhcCompositeState) => {
  //   return selectSignature(state)
  // })
  
  // const currentUserSpecifiedSignatureDate: string = useSelector((state: SbhcCompositeState) => {
  //   return selectUserSpecifiedSignatureDate(state)
  // })

  useEffect(() => {
    LOG.debug(`Homepage()::useEffect:  Entering`);
    if (subscription == null) {
      LOG.info(`Homepage()::useEffect(): Subscribing to user authentication state changes`)
      subscription = observable.subscribe(() => {
        handleAutenticatedUserStateChange()
      })
    } else {
      LOG.info(`Homepage()::useEffect(): Subscription already exists`)
    }
  
    (store.dispatch as any)((dispatch: Dispatch /* TODO: AppDispatch vs Dispatch */, getState: any) => {
      if (whoAmISvc.amILoggedIn()) {
        LOG.debug(`Homepage()::useEffect()::store.dispatch: preparing to call fetchExistingDiagnosticAnswers...`)
        fetchExistingUserAttributes(dispatch, getState)
        fetchExistingDiagnosticAnswers(dispatch, getState)
      } else {
        LOG.debug(`Homepage()::useEffect()::store.dispatch: NOT logged in.  Will NOT call fetchExistingDiagnosticAnswers...`)
      }
    })
    
    return () => {
      LOG.debug(`Homepage()::useEffect:  Unmounting...`);
      subscription.unsubscribe()
    }
  }, []);

  let currentlyLoggedInFlag: boolean = whoAmISvc.amILoggedIn();
  const handleAutenticatedUserStateChange = () => {
    currentlyLoggedInFlag = whoAmISvc.amILoggedIn()
    LOG.debug(`Homepage(): handleAutenticatedUserStateChange(): New state:  currentlyLoggedInFlag = ${currentlyLoggedInFlag ? 'AUTHENTICATED' : 'NOT authenticated'}`)
  
    // currentlyLoggedInFlag is a REGULAR variable.  Forcing the page to re-render
    setForcedRerenderAt(new Date().toISOString())
  }
  LOG.debug(`Homepage(): currentlyLoggedInFlag = ${currentlyLoggedInFlag ? 'AUTHENTICATED' : 'NOT authenticated'}`)

  
  const handleRedirectAsNeededComponentResponse = (numTimesThroughLogic: number, goWhere: string | null) => {
    LOG.debug(`handleRedirectAsNeededComponentResponse(): numTimesThroughLogic = ${d4l(numTimesThroughLogic)},  goWhere = ${d4l(goWhere)}`)

    setHomepageStillLoading(false)
    if (stringUtil.isPresent(goWhere!)) {

    }
  }

  return (<>
    <RedirectAsNeededComponent afterRunOnce={handleRedirectAsNeededComponentResponse} autoPerform="true"/>
    { homepageStillLoading && <> Loading...</> }
    { !homepageStillLoading && <>
      { !currentlyLoggedInFlag &&
        <>
        <header className="page-nav">
          <a href="https://www.secondbrainhealthcare.com/">
            <img src="https://images.squarespace-cdn.com/content/v1/62851121e6f44f16e25ce88c/33e00c1e-fcbd-45e5-a847-3751372acd63/Untitled+design+%281%29.png" alt="Second Brain Healthcare"/>
          </a>

          {/* <a onClick={scrollToRegistrationBlock} className="btn btn-primary btn-monique">Register for the free clinical study</a> */}
          { false && !currentlyLoggedInFlag &&
            <Link className="btn btn-primary btn-monique" to={`/signin`}>Sign In</Link> 
          }        
        </header>

        <section className="page-header-wrapper">
          <div className="page-header-inner" style={{ backgroundImage: `url(landing/hero2.jpg)`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <div className="text-block">
              <h1>Gut issues are frustrating. Getting an accurate diagnosis shouldn't be.</h1>
              <br/>
              <p>It takes over <b>3 years</b> to get an accurate diagnosis for gut symptoms. 
              <b> We are working on a better way. </b> 
              Enroll in our clinical study and find out what may be causing your symptoms. 
              </p>

              <div>
                { !currentlyLoggedInFlag && (
                  <>
                    <a onClick={scrollToRegistrationBlock} className="btn btn-light btn-monique">Get Started</a>
                  </> )
                }
                { !currentlyLoggedInFlag && (
                  <>
                    <a onClick={scrollToFaq} className="btn btn-primary btn-monique">Learn More</a>
                  </> )
                }
                { currentlyLoggedInFlag && 
                  (<Link className="btn btn-primary btn-monique" to={`/questionnaire`}>Complete Questionnaire</Link>)
                }
              </div>            
            </div>
          </div>
        </section>

        <section className="steps-wrapper">
          <h2>Clinical Study Journey</h2>
          <ol>
            <li style={{ backgroundImage: `url(landing/step1.jpg)`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
              <div className="inner">
                <span>01</span>
                <h3>Questionnaire</h3>
                <p>Complete online questionnaire (~15 minutes).</p>
              </div>
            </li>
            <li style={{ backgroundImage: `url(landing/step2.jpg)`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
              <div className="inner">
                <span>02</span>
                <h3>Doctor Follow-Up</h3>
                <p>Our doctors will call you to review the necessary labs you need to complete.</p>
              </div>
            </li>
            <li style={{ backgroundImage: `url(landing/step3.jpg)`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
              <div className="inner">
                <span>03</span>
                <h3>Labs</h3>
                <p>You’ll complete the necessary labs.</p>
              </div>
            </li>
            <li style={{ backgroundImage: `url(landing/step4.jpg)`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
              <div className="inner">
                <span>04</span>
                <h3>Diagnosis</h3>
                <p>Our doctors will call you with your diagnosis.</p>
              </div>
            </li>
          </ol>
          <button onClick={scrollToFaq} className="btn btn-outline-primary btn-monique">Learn more about our clinical study</button>
        </section>

        <section className="checklist-wrapper">
          <div className="checklist-inner">
            <h2>What the study doctor may diagnose</h2>
            <ul>
              <li>Irritable Bowel Syndrome - Constipation</li>
              <li>Irritable Bowel Syndrome - Diarrhea</li>
              <li>Irritable Bowel Syndrome - Mixed</li>
              <li>Celiac Disease</li>
              <li>Bloating</li>
              <li>Small Intestinal Bacterial Overgrowth (SIBO)</li>
              <li>Constipation</li>
              <li>Functional Dyspepsia</li>
              <li>H. pylori</li>
              <li>Sucrose Intolerance and SCID</li>
              <li>Fructose Intolerance</li>
              <li>Lactose Intolerance</li>
              <li>Gastroesophageal Reflux Disease (GERD)</li>
              <li>Bile Acid Malasborption</li>
            </ul>
          </div>
        </section>
        </>
      }

      { currentlyLoggedInFlag && (
        <>
          <header className="page-nav">
            <img src="https://images.squarespace-cdn.com/content/v1/62851121e6f44f16e25ce88c/33e00c1e-fcbd-45e5-a847-3751372acd63/Untitled+design+%281%29.png" alt="Second Brain Healthcare"/>
            {/* <a onClick={scrollToRegistrationBlock} className="btn btn-primary btn-monique">Register for the free clinical study</a> */}
          </header>

          <section className="page-header-wrapper">
            <div className="page-header-inner" style={{ backgroundImage: `url(landing/hero.jpg)`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
              <div className="text-block">
                <h1>Your path to a diagnosis starts here.</h1>
                <p>Welcome to our clinical study. The first step is to share your symptoms in our secure and confidential questionnaire.</p>
                <p>Expected time to complete: 15 minutes</p>
                <p><i>Tip: Please bookmark this page if you need to return later to finish the questionnaire.</i></p>
                <div>
                  { currentlyLoggedInFlag && 
                    (<Link className="btn btn-primary btn-monique" to={`/questionnaire`}>Complete Questionnaire</Link>)
                  }
                </div>            
              </div>
            </div>
          </section>
        </>
      )}



      { !currentlyLoggedInFlag && (
          <>
            <section className="faq-wrapper" id="faq">
              <div className="faq-inner">
                <h2>Frequently Asked Questions</h2>
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h3 className="accordion-header" id="headingOne">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                      What are the advantages of being a participant in this study?
                      </button>
                    </h3>
                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>Getting an accurate diagnosis is a crucial step in effectively managing your gut symptoms. This crucial step takes the average patient more than three years. The goal of this study is for participants to quickly receive a diagnosis and start on your path to feeling better.</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h3 className="accordion-header" id="headingTwo">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Are there risks involved in participating in this study?
                      </button>
                    </h3>
                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>The risks of taking blood may include fainting, pain and/or bruising. Rarely, there may be a small blood clot or infection where the needle punctures the skin. At no time will the blood collection exceed the amount or volume the study physician feels is appropriate.
    Information obtained about you in this study will be handled in a confidential manner and will not be shared with anyone outside of the study in a manner that would identify yourself. Any health information collected through this diagnostic questionnaire will only be used for the purpose of this study with the sponsor and the study physician and will not be shared with any other health care provider or company.
    There is a risk that your diagnosis may not be accurate, but the study physician will discuss any diagnoses with you. There are no other foreseeable risks to participating.</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h3 className="accordion-header" id="headingThree">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        Can I stop participating at any time?
                      </button>
                    </h3>
                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>Yes, participants have the right to change their minds at any time even after they have started the clinical trial or at any time during the trial.</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h3 className="accordion-header" id="headingFour">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        Am I eligible to participate in this study?
                      </button>
                    </h3>
                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>All clinical studies have rules about who can and cannot participate. These rules are called “eligibility criteria” or inclusion/exclusion criteria. The criteria for this study are based on patient symptoms, previous treatment history, and other medical conditions. The eligibility criteria define the patient population that is being studied and are designed to protect the safety of participants in the study. Because of the criteria, not everyone will qualify to participate in the study.</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h3 className="accordion-header" id="headingFive">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                        Will my insurance cover any visits?
                      </button>
                    </h3>
                    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>The role of personal insurance can vary by region and by study and will be discussed with each participant before any visits occur.</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h3 className="accordion-header" id="headingSix">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                        Will I be compensated for participating in this study?
                      </button>
                    </h3>
                    <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>No. However, upon completion of the study, participants who complete a survey to provide feedback on their experience will receive an Amazon gift card.</p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h3 className="accordion-header" id="headingSeven">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                        Where does the study take place?
                      </button>
                    </h3>
                    <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <p>Mostly from the comfort of your home but you will likely need to complete required lab work at a lab near you.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
      )}


      { !currentlyLoggedInFlag && (
        <section className="cta-wrapper">
          <div className="image-block">
            <img src="landing/cta.jpg" alt=""/>
          </div>
          <div className="form-block" id="registration-block">
            <h2>Register for the free clinical study</h2>
            <p>Let's start your clinical study journey. Our 15 minute questionnaire is the first step.</p>                
            <p><b>You will receive an email with a verification code to use on the next screen. If you have not received an email within 5 minutes, please check your spam folder.</b></p>
            <SignupLoginHintCaptureFormComponent/>
          </div>
        </section>
      )}


      <footer>
        <p>Copyright 2023 Secon<Link to={`/devmode`} className="no-design">d</Link> Brain Healthcare</p>   
        <ul>
          <li><a target="_blank"href="/SB-001-CL-102_Informed_Consent_Form_09Jan2024.pdf">Informed Consent</a></li>
          <li><a target="_blank" href="/SB_Website_Privacy_Policy.pdf">Privacy Policy</a></li>
        </ul>     
      </footer>
    </> }
  </>)
}