import React, { useState, useReducer, useEffect } from 'react'
import { useSelector, useDispatch, DefaultRootState } from 'react-redux'
import safeStringify from '../string/safeStringify'
import {
  setHeightInInches, setAgeInYears, setWeightInLbs, selectHeightInInches, selectAgeInYears, selectWeightInLbs,
  selectEverNausea,
  setEverNausea,
  selectEverVomiting,
  setEverVomiting,
  selectEverAbdominalPain,
  selectEverStiffOrLimitationOfMovement,
  setEverAbdominalPain,
  selectAbdominalPainHowOften,
  selectAbdominalPainWhere,
  setAbdominalPainHowOften,
  setAbdominalPainWhere,
  setEverStiffOrLimitationOfMovement,
  syncDiagnosticAnswersToBackEndThunk,
  setQuestionnaireSubmittedFlag,
  setBowelTroubleHowOften,
  selectBowelTroubleHowOften,
  IThunkApi,
  setClearMyQuestionnaireDangerWillRobinson,
  clearMyQuestionnaireUpstreamDangerWillRobinsonThunk,
  selectEntireQuestionnaireSliceOfState,
  setClearMyFeedbackDangerWillRobinson,
  clearMyFeedbackUpstreamDangerWillRobinsonThunk,
  setUnsubmitDangerWillRobinson,
  unsubmitUpstreamDangerWillRobinsonThunk,
  setYesOrNo
} from '../questionnaire/questionnaireSlice'
import { CurrentPreviousNeverCodeEnum, CurrentPreviousNeverLabelEnum, everCurrentOrPreviously } from '../commonSrc/constant/currentPreviousNeverConstant';
import SelectCurrentPreviousNeverComponent from '../component/SelectCurrentPreviousNeverComponent';
import { UpTo3PlusTimesPerWeekCodeEnum, UpTo3PlusTimesPerWeekLabelEnum } from '../commonSrc/constant/upToThreePlusTimesPerWeekConstant';
import { UpperLowerBothCodeEnum, UpperLowerBothLabelEnum } from '../commonSrc/constant/upperLowerBothConstant';

import * as cssConstant from '../constant/cssConstant'
import { StateScalarAttribute, AllAttrEnum, SubtypeObject } from '../commonSrc/constant/type';
import { cpus } from 'os';
import { SbhcCompositeState } from '../state/SbhcCompositeState';
import { AsyncThunkAction } from '@reduxjs/toolkit'
import diContainer from '../inversify.config'
import { WhoAmISvc } from "../auth/WhoAmISvc";
import DI_TYPES from "../diTypes";
import { Link, useSearchParams } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import { SbNotificationPublisher } from '../notification/SbNotificationPublisher';
import { SbNotificationCode } from '../commonSrc/notification/SbNotificationCode';
import { IncompleteAnswersException } from '../exception/IncompleteAnswersException';
import { QuestionnaireAttributes } from '../commonSrc/questionnaire/allQuestionnaireAttributes';
import IntakeQuestionnaireProgressComponent from '../progress/IntakeQuestionnaireProgressComponent';
import CurrentPreviousNeverQuestionComponent from '../question/CurrentPreviousNeverQuestionComponent';
import { setEverString, selectEverString } from '../questionnaire/questionnaireSlice';
import UpToThreeTimePerWeekQuestionComponent from '../question/UpToThreeTimesPerWeekQuestionComponent'
import YesOrNoQuestionComponent from '../question/YesOrNoQuestionComponent'
import UpperLowerBothQuestionComponent from '../question/UpperLowerBothQuestionComponent'
import AlwaysSometimesNeverQuestionComponent from '../question/AlwaysSometimesNeverQuestionComponent'
import OneToThreeXDailyMoreOrLessQuestionComponent from '../question/OneToThreeXDailyMoreOrLessQuestionComponent'
import OneToTenQuestionComponent from '../question/OneTo10QuestionComponent'
import { hasBowelMovementFrequencyChangedLast6Months, hasConstipation, hasConstipationOrHasDiarrheaOrHasSteatorrhea, hasDiarrhea, hasDiarrheaOrHasSteatorrhea, hasNarrowingStoolLast6Months, hasOrHadAbdominalPain, hasSteatorrhea, is6CurrentOrPrevious_abdominalPain, isYesTo6b_68_105_107_49_or_108 } from '../commonSrc/questionnaire/numberedNamedQuestionUtil'
import { QuestionnaireSliceOfState } from '../commonSrc/questionnaire/QuestionnaireSliceOfState'
import FeetAndInchesComponent from '../question/FeetAndInchesComponent'
import { useDeveloperMode } from "../developer/useDeveloperMode";
import { TOTAL_NUM_STEPS } from '../commonSrc/constant/stepDetail'
import { useQuestionnaireSubmitted } from './useQuestionnaireSubmitted'
import OneToSixPlusMonthsQuestionComponent from '../question/OneToSixPlusMonthsQuestionComponent'
import { FrequentlySometimesRarelyLabelEnum } from '../commonSrc/constant/frequentlySometimesRarelyConstant'
import FrequentSometimesNeverQuestionComponent from '../question/FrequentlySometimesRarelyQuestionComponent'
import ConditionalNestedQuestionComponent from '../question/ConditionalNestedQuestionComponent'
import { isHasNotTrue, isHasTrue, isTrueLike, isOneOfStrictlyTrueOrStrictlyFalse } from "../commonSrc/boolean/booleanUtil";
import CheckAllThatApplyQuestionComponent from '../question/CheckAllThatApplyQuestionComponent'
import { YesOrNoCodeEnum } from '../commonSrc/constant/yesOrNoConstant'
import PoopTypeNumberQuestionComponent from '../question/PoopTypeNumberQuestionComponent'
import MonthsToYearsQuestionComponent from '../question/MonthsToYearsQuestionComponent'
import { getLogger } from '../log/getLogger'
import { useAuth0 } from "@auth0/auth0-react"


const LOG = getLogger('questionnaire.QuestionnaireComponent')

// function debounce (fn: (...args: any[]) => void, time: any) {
//   let timeoutId: any
//   // return wrapper
//   function wrapper (...args: Array<any>) {
//     if (timeoutId) {
//       clearTimeout(timeoutId)
//     }
//     timeoutId = setTimeout(() => {
//       timeoutId = null
//       fn(...args)
//     }, time)
//   }

//   return wrapper
// }

let debounceTimeout: any;
// https://gist.github.com/ca0v/73a31f57b397606c9813472f7493a940
export const debounce = <F extends (...args: any[]) => any>(func: F, waitFor: number) => {
  // let timeout: any

  return (...args: Parameters<F>): Promise<ReturnType<F>> =>
    new Promise(resolve => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout)
      }

      debounceTimeout = setTimeout(() => resolve(func(...args)), waitFor)
    })
}

export default function QuestionnaireComponent() {
  const navigate = useNavigate();
  const [isQuestionnaireSubmitted] = useQuestionnaireSubmitted()
  const [developerMode, updateDeveloperMode] = useDeveloperMode();
  const { user, isAuthenticated, isLoading, error, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (isQuestionnaireSubmitted) {
      LOG.debug(`QuestionnaireComponent() - It's deemed that the questionnaire has been submitted.  Navigating to /findings`)
      navigate(`/findings`, { replace: true });
    }
  }, [getAccessTokenSilently, user?.sub]);

  const sbNotificationPublisher: SbNotificationPublisher = diContainer.get<SbNotificationPublisher>(DI_TYPES.SbNotificationPublisher)

  const [searchParams, setSearchParams] = useSearchParams();
  const stepNumberAsString: string | null = searchParams.get("step")
  LOG.debug(`QuestionnaireComponent(): Entering with stepNumberAsString = ${stepNumberAsString}`)

  let submitInProgressFlag: boolean = false

  const stepNumberAsNumber: number | null = (() => {
    if (stepNumberAsString == null) { return 1; }

    try {
      return parseInt(stepNumberAsString, 10)
    } catch (err) {
      return 1;
    }
  })()

  const isSubmitVsSave = (): boolean => {
    if (isTrueLike(process.env.REACT_APP_ONLY_SAVE_NO_SUBMIT || '')) { return false }

    if (stepNumberAsNumber >= TOTAL_NUM_STEPS) {
      return true
    }
    return false;
  }

  const currentStepNumber: number = stepNumberAsNumber

  // const currentHeightInInches = () => {
  //   const retval = useSelector((state) => {
  //     return selectHeightInInches(state)
  //   })
  //   console.log(`currentHeightInInches(): Return ${retval}`)
  //   return retval
  // }

  const whoAmISvc: WhoAmISvc = diContainer.get<WhoAmISvc>(DI_TYPES.WhoAmISvc)
  const loggedInFlag: boolean = whoAmISvc.amILoggedIn()
  const oauth2Username: string | undefined = whoAmISvc.getMyUsername()
  const oauth2UserId: string | undefined = whoAmISvc.getMyUserId()

  const [syncDiagnosticAnswersToBackEndThunkStatus, setSyncDiagnosticAnswersToBackEndStatus] = useState('idle')

  const currentHeightInInches: StateScalarAttribute<number> = useSelector((state: SbhcCompositeState) => {
    return selectHeightInInches(state)
  })
  const currentAgeInYears: StateScalarAttribute<number> = useSelector((state: SbhcCompositeState) => {
    return selectAgeInYears(state)
  })
  const currentWeightInLbs: StateScalarAttribute<number> = useSelector((state: SbhcCompositeState) => {
    return selectWeightInLbs(state)
  })
  const realtimeEverNausea: StateScalarAttribute<string> = useSelector((state: SbhcCompositeState) => {
    return selectEverNausea(state)
  })
  const realtimeEverVomitting: StateScalarAttribute<string> = useSelector((state: SbhcCompositeState) => {
    return selectEverVomiting(state)
  })

  const realtimeQuestionnaireState: QuestionnaireAttributes = useSelector((state: SbhcCompositeState) => {
    return state?.questionnaire
  })

  function getRealtimeValue( attrName: string ): boolean | number | string | null | undefined {
    const retval = (realtimeQuestionnaireState as any)?.[attrName]
    LOG.debug(`getRealtimeValue(): Returning '${retval}' (${typeof retval}),  for attrName = ${attrName}`)
    return retval
  }

  const currentEverAbdominalPain: StateScalarAttribute<string> = useSelector((state: SbhcCompositeState) => {
    return selectEverAbdominalPain(state)
  })
  const currentEverBowelTrouble: StateScalarAttribute<string> = useSelector((state: SbhcCompositeState) => {
    return selectEverAbdominalPain(state)
  })
  const currentDiagnosticAnswersCanIUseThis: QuestionnaireAttributes = {
    everNausea: useSelector((state: SbhcCompositeState) => { return selectEverNausea(state) }),
    everVomiting: useSelector((state: SbhcCompositeState) => { return selectEverVomiting(state) }),
    everAbdominalPain: useSelector((state: SbhcCompositeState) => { return selectEverAbdominalPain(state) })
  } as any as QuestionnaireAttributes

  const currentEverStiffOrLimitationOfMovement: StateScalarAttribute<string> = useSelector((state: SbhcCompositeState) => {
    return selectEverStiffOrLimitationOfMovement(state)
  })
  // TODO: Everything is a string?
  // const currentAbdominalPainHowOften: StateScalarAttribute<string> = useSelector((state: DefaultRootState) => selectAbdominalPainHowOften(state))     
  const currentAbdominalPainHowOften: StateScalarAttribute<string> = useSelector((state: SbhcCompositeState) => selectAbdominalPainHowOften(state))
  const currentAbdominalPainWhere: StateScalarAttribute<string> = useSelector((state: SbhcCompositeState) => selectAbdominalPainWhere(state))

  const currentEntireQuestionnaireSliceOfState: QuestionnaireSliceOfState = useSelector((state: SbhcCompositeState) => {
    return selectEntireQuestionnaireSliceOfState(state)
  })

  const currentIsYesTo6b_68_105_107_49_or_108: boolean = useSelector((state: SbhcCompositeState) => {
    return isYesTo6b_68_105_107_49_or_108(currentEntireQuestionnaireSliceOfState)
  })

  const realtimeHasConstipationOrHasDiarrheaOrHasSteatorrhea: boolean = useSelector((state: SbhcCompositeState) => {
    return hasConstipationOrHasDiarrheaOrHasSteatorrhea(currentEntireQuestionnaireSliceOfState)
  })

  const realtimeHasSteatorrhea: boolean = useSelector((state: SbhcCompositeState) => {
    return hasSteatorrhea(currentEntireQuestionnaireSliceOfState)
  })

  const realtimeHasBowelMovementFrequencyChangedLast6Months: boolean = useSelector((state: SbhcCompositeState) => {
    return hasBowelMovementFrequencyChangedLast6Months(currentEntireQuestionnaireSliceOfState)
    // return currentEntireQuestionnaireSliceOfState.hasBowelMovementFrequencyChangedLast6Months
  })

  const realtimeHasDiarrheaOrHasSteatorrhea: boolean = useSelector((state: SbhcCompositeState) => {
    return hasDiarrheaOrHasSteatorrhea(currentEntireQuestionnaireSliceOfState)
  })

  const realtimeHasConstipation: boolean = useSelector((state: SbhcCompositeState) => {
    return hasConstipation(currentEntireQuestionnaireSliceOfState)
  })

  const realtimeHasDiarrhea: boolean = useSelector((state: SbhcCompositeState) => {
    return hasDiarrhea(currentEntireQuestionnaireSliceOfState)
  })

  const realtimeHasNarrowingStoolLast6Months: boolean = useSelector((state: SbhcCompositeState) => {
    return hasNarrowingStoolLast6Months(currentEntireQuestionnaireSliceOfState)
  })

  const realtimeIs6CurrentOrPrevious: boolean = useSelector((state: SbhcCompositeState) => {
    return is6CurrentOrPrevious_abdominalPain(currentEntireQuestionnaireSliceOfState)
  })

  const realtimeHasEpigastricPainOrBurning: boolean = useSelector((state: SbhcCompositeState) => {
    return isTrueLike(currentEntireQuestionnaireSliceOfState.hasEpigastricPainOrBurning)
  })

  // TODO: Is this question 119?
  const realtimeHasEarlyFullnessDuringMeal: boolean = useSelector((state: SbhcCompositeState) => {
    return isTrueLike(currentEntireQuestionnaireSliceOfState.hasEarlyFullnessDuringMeal)
  })


  // TODO: What is this for?
  // useEffect(() => {
  //   console.log(currentHeightInInches)
  // }, [currentHeightInInches, currentAgeInYears, currentWeightInLbs])

  const dispatch = useDispatch()
  //   const dispatch = useDispatch()
  // const [incrementAmount, setIncrementAmount] = useState('2')

  // const [state, dispatch] = useReducer((state: any, action: any) => {
  //   console.log(`QuestionnaireComponent::useReducer(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)

  //   switch (action.type) {
  //     // do something with the action
  //   }
  // }, 0);

  // https://daveceddia.com/usereducer-hook-examples/
  // https://blog.logrocket.com/guide-to-react-usereducer-hook/
  // let numbers = [1, 2, 3];
  // let sum = numbers.reduce((total, number) => {
  //   return total + number;
  // }, 0);

  // const reducer = (state: any, action: any): any => {
  //   console.log(`QuestionnaireComponent::reducer(): state = ${safeStringify(state)},  action = ${safeStringify(action)}`)

  //   switch (action.type) {
  //     // do something with the action
  //   }
  //   state.heightInInches = action.payload

  //   console.log(`QuestionnaireComponent::reducer(): returning new state = ${safeStringify(state)}`)
  //   return state
  // }
  // const initialState = {heightInInches: ''};
  // console.log(`QuestionnaireComponent::reducer(): initialState = ${safeStringify(initialState)}`)

  // const [state, dispatch] = useReducer(reducer, initialState);

  // https://gist.github.com/krstffr/245fe83885b597aabaf06348220c2fe9
  const onDiagnosticAnswersChangedInner = (): void => {
    LOG.debug(`onDiagnosticAnswersChangedInner(): Entering`)

    try {
      setSyncDiagnosticAnswersToBackEndStatus('pending')
      // dispatch(setHeightInInches(event.target.value)
      // await dispatch(setHeightInInches(event.target.value))
      // await dispatch(syncDiagnosticAnswersToBackEndThunk())
      // https://redux-toolkit.js.org/api/createAsyncThunk
      // dispatch(syncDiagnosticAnswersToBackEndThunk())
      // // .unwrap()
      // .then((originalPromiseResult: any) => {
      //   // handle result here
      //   console.log(`onDiagnosticAnswersChangedInner(): originalPromiseResult = `, originalPromiseResult)
      // })
      // .catch((rejectedValueOrSerializedError: any) => {
      //   // handle error here
      //   console.log(`onDiagnosticAnswersChangedInner(): rejectedValueOrSerializedError = `, rejectedValueOrSerializedError)

      // })

      LOG.debug(`onDiagnosticAnswersChangedInner(): Preparing to dispatch a syncDiagnosticAnswersToBackEndThunk()...`)
      // Expected 1 arguments, but got 0.  TS2554
      const originalPromiseResult: AsyncThunkAction<any, void, IThunkApi> = dispatch(syncDiagnosticAnswersToBackEndThunk())
      // TODO: unwrap or no?
      // .unwrap()
      // LOG.debug('onDiagnosticAnswersChangedInner(): Finished dispatching a syncDiagnosticAnswersToBackEndThunk().  originalPromiseResult = ', originalPromiseResult)
      LOG.debug(`onDiagnosticAnswersChangedInner(): Finished dispatching a syncDiagnosticAnswersToBackEndThunk().  originalPromiseResult = ${safeStringify(originalPromiseResult)}`)


      // dispatch(syncDiagnosticAnswersToBackEndThunk())
      // await dispatch(syncDiagnosticAnswersToBackEndThunk())
      // await dispatch(syncDiagnosticAnswersToBackEndThunk()).unwrap())
    } catch (err) {
      LOG.error('onDiagnosticAnswersChangedInner(): err = ', err)
    } finally {
      setSyncDiagnosticAnswersToBackEndStatus('idle')
    }
  }

  const onDiagnosticAnswersChanged = debounce(() => {
    return onDiagnosticAnswersChangedInner()
  }, 750)

  const onClearMyQuestionnaireDangerWillRobinsonUpstream = () => {
    LOG.warn('onClearMyQuestionnaireDangerWillRobinsonUpstream(): Entering')

    try {
      setSyncDiagnosticAnswersToBackEndStatus('pending')

      LOG.debug(`onClearMyQuestionnaireDangerWillRobinsonUpstream(): Preparing to dispatch a syncDiagnosticAnswersToBackEndThunk()...`)
      const originalPromiseResult: AsyncThunkAction<any, void, IThunkApi> = dispatch(clearMyQuestionnaireUpstreamDangerWillRobinsonThunk())
      LOG.debug(`onClearMyQuestionnaireDangerWillRobinsonUpstream(): Finished dispatching a clearMyQuestionnaireUpstreamDangerWillRobinsonThunk().  originalPromiseResult = ${safeStringify(originalPromiseResult)}`)
    } catch (err) {
      LOG.error('onClearMyQuestionnaireDangerWillRobinsonUpstream(): err = ', err)
    } finally {
      setSyncDiagnosticAnswersToBackEndStatus('idle')
    }
  };

  const onClearMyFeedbackDangerWillRobinsonUpstream = () => {
    LOG.warn('onClearMyFeedbackDangerWillRobinsonUpstream(): Entering')

    try {
      setSyncDiagnosticAnswersToBackEndStatus('pending')

      LOG.debug(`onClearMyFeedbackDangerWillRobinsonUpstream(): Preparing to dispatch a syncDiagnosticAnswersToBackEndThunk()...`)
      const originalPromiseResult: AsyncThunkAction<any, void, IThunkApi> = dispatch(clearMyFeedbackUpstreamDangerWillRobinsonThunk())
      LOG.debug(`onClearMyFeedbackDangerWillRobinsonUpstream(): Finished dispatching a clearMyQuestionnaireUpstreamDangerWillRobinsonThunk().  originalPromiseResult = ${safeStringify(originalPromiseResult)}`)
    } catch (err) {
      LOG.error('onClearMyFeedbackDangerWillRobinsonUpstream(): err = ', err)
    } finally {
      setSyncDiagnosticAnswersToBackEndStatus('idle')
    }
  };

  const onUnsubmitDangerWillRobinsonUpstream = () => {
    LOG.warn('onUnsubmitDangerWillRobinsonUpstream(): Entering')

    try {
      setSyncDiagnosticAnswersToBackEndStatus('pending')

      LOG.debug(`onUnsubmitDangerWillRobinsonUpstream(): Preparing to dispatch a syncDiagnosticAnswersToBackEndThunk()...`)
      const originalPromiseResult: AsyncThunkAction<any, void, IThunkApi> = dispatch(unsubmitUpstreamDangerWillRobinsonThunk())
      LOG.debug(`onUnsubmitDangerWillRobinsonUpstream(): Finished dispatching a unsubmitUpstreamDangerWillRobinsonThunk().  originalPromiseResult = ${safeStringify(originalPromiseResult)}`)
    } catch (err) {
      LOG.error('onUnsubmitDangerWillRobinsonUpstream(): err = ', err)
    } finally {
      setSyncDiagnosticAnswersToBackEndStatus('idle')
    }
  };

  const onUnsubmitAndClearHypothesesDangerWillRobinson = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
    LOG.debug(`onUnsubmitAndClearHypothesesDangerWillRobinson() - Entering with event = ${safeStringify(event)}`)
    dispatch(setUnsubmitDangerWillRobinson())
    onUnsubmitDangerWillRobinsonUpstream()
    dispatch(setClearMyFeedbackDangerWillRobinson())
    onClearMyFeedbackDangerWillRobinsonUpstream()
    dispatch(setQuestionnaireSubmittedFlag(false))
    navigate(`/questionnaire`, { replace: true });
  }

  const onChangeHeightInInches = (event: React.ChangeEvent<HTMLInputElement>): void => {
    LOG.debug(`onChangeHeightInInches() - Entering with event = ${safeStringify(event)}`)
    // string vs number.  https://stackoverflow.com/questions/41773778/sending-number-instead-of-string-from-select-in-react-component
    dispatch(setHeightInInches(parseInt(event.target.value, 10)))
    onDiagnosticAnswersChanged()
  }

  const onChangeAgeInYears = (event: React.ChangeEvent<HTMLInputElement>): void => {
    LOG.debug(`onChangeAgeInYears() - Entering with event = ${safeStringify(event)}`)
    dispatch(setAgeInYears(parseInt(event.target.value, 10)))
    onDiagnosticAnswersChanged()
  }

  const onChangeWeightInLbs = (event: React.ChangeEvent<HTMLInputElement>): void => {
    LOG.debug(`onChangeWeightInLbs() - Entering with event = ${safeStringify(event)}`)
    dispatch(setWeightInLbs(parseInt(event.target.value, 10)))
    onDiagnosticAnswersChanged()
  }

  const onChangeEverNausea = async (event: React.ChangeEvent<HTMLInputElement> | any): Promise<void> => {
    LOG.debug(`onChangeEverNausea() - Entering with event = ${safeStringify(event)}`)
    dispatch(setEverNausea(event.target.value))
    onDiagnosticAnswersChanged()
  }

  const onChangeEverVomiting = async (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>): Promise<void> => {
    LOG.debug(`onChangeEverVomiting() - Entering with event = ${safeStringify(event)}`)
    dispatch(setEverVomiting(event.target.value))
    onDiagnosticAnswersChanged()
  }

  const onChangeEverAbdominalPain = async (event: React.ChangeEvent<HTMLSelectElement>): Promise<void> => {
    LOG.debug(`onChangeEverAbdominalPain() - Entering with event = ${safeStringify(event)}`)
    dispatch(setEverAbdominalPain(event.target.value))
    onDiagnosticAnswersChanged()
  }

  const onChangeAbdominalPainHowOften = async (event: React.ChangeEvent<HTMLSelectElement>): Promise<void> => {
    LOG.debug(`onChangeAbdominalPainHowOften() - Entering with event = ${safeStringify(event)}`)
    dispatch(setAbdominalPainHowOften(event.target.value))
    onDiagnosticAnswersChanged()
  }

  // const onChangeBowelTroubleHowOften = async (event: React.ChangeEvent<HTMLSelectElement>): Promise<void> => {
  //   LOG.debug(`onChangeBowelTroubleHowOften() - Entering with event = ${safeStringify(event)}`)
  //   dispatch(setBowelTroubleHowOften(event.target.value))
  //   onDiagnosticAnswersChanged()
  // }    

  const onChangeAbdominalPainWhere = async (event: React.ChangeEvent<HTMLSelectElement>): Promise<void> => {
    LOG.debug(`onChangeAbdominalPainWhere() - Entering with event = ${safeStringify(event)}`)
    dispatch(setAbdominalPainWhere(event.target.value))
    onDiagnosticAnswersChanged()
  }

  const onClearMyQuestionnaireDangerWillRobinson = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
    LOG.debug(`onClearMyQuestionnaireDangerWillRobinson() - Entering with event = ${safeStringify(event)}`)
    dispatch(setClearMyQuestionnaireDangerWillRobinson())
    onClearMyQuestionnaireDangerWillRobinsonUpstream()
    dispatch(setQuestionnaireSubmittedFlag(false))
    navigate(`/questionnaire`, { replace: true });

  }

  const onClearMyFeedbackDangerWillRobinson = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
    LOG.debug(`onClearMyFeedbackDangerWillRobinson() - Entering with event = ${safeStringify(event)}`)
    dispatch(setClearMyFeedbackDangerWillRobinson())
    onClearMyFeedbackDangerWillRobinsonUpstream()
    dispatch(setQuestionnaireSubmittedFlag(false))
    navigate(`/questionnaire`, { replace: true });
  }

  const onUnsubmitDangerWillRobinson = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
    LOG.debug(`onUnsubmitDangerWillRobinson() - Entering with event = ${safeStringify(event)}`)
    dispatch(setUnsubmitDangerWillRobinson())
    onUnsubmitDangerWillRobinsonUpstream()
    dispatch(setQuestionnaireSubmittedFlag(false))
    navigate(`/questionnaire`, { replace: true });
  }

  const onChangeEverStiffOrLimitationOfMovement = async (event: React.ChangeEvent<HTMLSelectElement>): Promise<void> => {
    LOG.debug(`onChangeEverStiffOrLimitationOfMovement() - Entering with event = ${safeStringify(event)}`)
    dispatch(setEverStiffOrLimitationOfMovement(event.target.value))
    onDiagnosticAnswersChanged()
  }


  function isUnsetNumber(value: number): boolean {
    LOG.debug(`isUnsetNumber() - Entering with value = ${value} (${typeof value})`)
    return (typeof value === 'undefined') || value == null || (value as any as string) === '' || value <= 0
  }

  function isUnsetEverQuestion(value: string): boolean {
    LOG.debug(`isUnsetEverQuestion() - Entering with value = ${value} (${typeof value})`)
    return (typeof value === 'undefined') || value == null || value === '' || value === CurrentPreviousNeverCodeEnum.UNSET
  }

  function isUnsetQuestion(value: string | number | boolean | undefined | null): boolean {
    LOG.debug(`isUnsetQuestion() - Entering with value = ${value} (${typeof value})`)
    return (typeof value === 'undefined') || value == null || value === '' || value === CurrentPreviousNeverCodeEnum.UNSET
  }

  function isAnswerPresent(value: string | number | boolean | undefined | null): boolean {
    LOG.debug(`isAnswerPresent() - Entering with value = ${value} (${typeof value})`)
    return !isUnsetQuestion(value)
  }

  function areAllTheseGenericAnswersSet(arrayOfAttrName: Array<AllAttrEnum | SubtypeObject>): boolean {
    const retval = arrayOfAttrName.every((attr: AllAttrEnum|SubtypeObject) => {
      if (typeof attr === 'object') {
        const subtypeObject: SubtypeObject = attr
        if (subtypeObject.predicate == true) {
          // If the subtype predicate is truthy, 
          // then consider the subset as relevant and required
          return areAllTheseGenericAnswersSet(subtypeObject.nested)
        } else {
          // Else If the subtype predicate is falsey, 
          // then consider the subset as NOT relevant and
          // ignore them from the consideration
          return true
        }
      }
      return isAnswerPresent(getRealtimeValue(AllAttrEnum[attr as AllAttrEnum]))
    })

    LOG.debug(`areAllTheseGenericAnswersSet() - Returning ${retval},  for arrayOfAttrName = ${JSON.stringify(arrayOfAttrName)}`)
    return retval
  }

  function isValidAnswersThruStepNum1(): boolean { return true }

  function isValidAnswersThruStepNum2(): boolean {
    LOG.debug(`isValidAnswersThruStepNum2() - Entering`)

    // if (isUnsetQuestion(getRealtimeValue('everNausea')) 
    //     || isUnsetQuestion('everVomiting')) {
    //       // expected these to all be set before leaving page one.  But at least one of them one of them is unset
    //       return false
    //     }
    // return true;

    return areAllTheseGenericAnswersSet([
      AllAttrEnum.everNausea,
      AllAttrEnum.everNausea, 
      AllAttrEnum.everVomiting, 
      AllAttrEnum.everAbdominalPain, 
      { 
        predicate: realtimeHasOrHadAbdominalPain, 
        nested: [
          AllAttrEnum.abdominalPainHowLong,
          AllAttrEnum.abdominalPainHowOften,
          AllAttrEnum.abdominalPainWhere,
          AllAttrEnum.asnRelievedOrRelatedToDefecation
        ] 
      },
      AllAttrEnum.everBloating,
      AllAttrEnum.everBurping,
      AllAttrEnum.everDyspepsia,
      AllAttrEnum.everFlatulence,
      AllAttrEnum.everAbdominalDistension
    ])
  }

  function isValidAnswersThruStepNum3(): boolean { 
    return areAllTheseGenericAnswersSet([
      AllAttrEnum.hasDifficultyOrPainSwallowing,
      AllAttrEnum.everRegurgitation,
      AllAttrEnum.everSourOrBitterTaste,
      AllAttrEnum.everFrequentCoughing,
      AllAttrEnum.everSwollenAndOrOrDiscoloredTongue,
      AllAttrEnum.everFrequentNeedToClearThroat,
      AllAttrEnum.everCankerSores,
      AllAttrEnum.everExcessiveMucus,
      AllAttrEnum.everHorseness,
      AllAttrEnum.everTonguePain,
      AllAttrEnum.everChestPain,
      AllAttrEnum.hasEpigastricPainOrBurning,
      { predicate: realtimeHasEpigastricPainOrBurning, 
        nested: [AllAttrEnum.epigastricPainOrBurningHowLong] },
      { predicate: currentIsYesTo6b_68_105_107_49_or_108,
        nested: [
          AllAttrEnum.hasWorseLargeMeals,
          AllAttrEnum.hasWorseLayingDown,
          AllAttrEnum.hasWorseAcidic,
          AllAttrEnum.hasReliefTums,
          AllAttrEnum.hasReliefTolaids,
          AllAttrEnum.hasReliefMylanta,
          AllAttrEnum.hasReliefH2Blocker,
          AllAttrEnum.hasReliefProtonPumpInhibitor,
        ] 
      }
    ])    
  }

  function isValidAnswersThruStepNum4(): boolean { 
    return areAllTheseGenericAnswersSet([
      AllAttrEnum.hasDiarrhea,
      AllAttrEnum.hasConstipation,
      AllAttrEnum.hasSteatorrhea,
      { predicate: realtimeHasDiarrhea, 
        nested: [
          AllAttrEnum.bowelMovementHowOften,
          AllAttrEnum.hasNarrowingStoolLast6Months,
          AllAttrEnum.hasBowelMovementFrequencyChangedLast6Months,
          AllAttrEnum.hasMidnightDiarrhea,
        ] 
      },
      { predicate: realtimeHasConstipation, 
        nested: [
          AllAttrEnum.bowelMovementHowOften,
          AllAttrEnum.hasNarrowingStoolLast6Months,
          AllAttrEnum.hasBowelMovementFrequencyChangedLast6Months,
          // TODO; Did they skip the picture selection?  Cant tell
          AllAttrEnum.hasStrainingBowelMovement,
          AllAttrEnum.hasHardOrLumpyStools,
          AllAttrEnum.hasSensationIncompleteBowelMovement,
          AllAttrEnum.hasSensationOfAnorectalBlockageOrObstruction,
          AllAttrEnum.hasFewerThan3SpontaneousBowelMovementsWeekly,
          AllAttrEnum.fsrLooseStools,
          AllAttrEnum.hasManeuversToAchieveBowelMovement,
        ] 
      },
      { predicate: realtimeHasSteatorrhea, 
        nested: [
          AllAttrEnum.bowelMovementHowOften,
          AllAttrEnum.hasNarrowingStoolLast6Months,
          AllAttrEnum.hasBowelMovementFrequencyChangedLast6Months,
          AllAttrEnum.hasMidnightDiarrhea,
        ] 
      },     
      AllAttrEnum.hasCurrentlyBloodInStool,
    ])    
  }
  function isValidAnswersThruStepNum5(): boolean { 
    return areAllTheseGenericAnswersSet([
      AllAttrEnum.everUnexpectedWeightLoss10In3,
      AllAttrEnum.everExcessiveWeightGain,
      AllAttrEnum.everLossOfAppetite,
      AllAttrEnum.everWaterRetention,
      AllAttrEnum.hasSymptomsWorsenWithGlutenIngestion,
      AllAttrEnum.hasEarlyFullnessDuringMeal,
      { predicate: realtimeHasEarlyFullnessDuringMeal, 
        nested: [AllAttrEnum.earlyFullnessSymptomsHowLong] }
      // AllAttrEnum.,
      // AllAttrEnum.,
      // AllAttrEnum.,
      // AllAttrEnum.,
      // AllAttrEnum.,
      // AllAttrEnum.,

    ])  
  }
  function isValidAnswersThruStepNum6(): boolean { 
    return areAllTheseGenericAnswersSet([
      AllAttrEnum.everSluggishness,
      AllAttrEnum.everBrainFog
    ])
   }
  function isValidAnswersThruStepNum7(): boolean { 
    return areAllTheseGenericAnswersSet([
      AllAttrEnum.hasHyperlipidemia,
      AllAttrEnum.hasType2Diabetes,
      AllAttrEnum.hasType1Diabetes,
      AllAttrEnum.hasOpiodUse,
      AllAttrEnum.hasBowelObstruction,
      AllAttrEnum.hasObesity,
      AllAttrEnum.hasRadiationToIntestines,
      AllAttrEnum.hasTerminalIlealResection,
      AllAttrEnum.hasIleocecalValveResection,
      AllAttrEnum.hasBowelStrictureOrAdhesions,
      AllAttrEnum.hasSmallBowelDiverticulosis,
      AllAttrEnum.hasAbdominalSurgeryHistory,
      AllAttrEnum.hasIleocectomyHistory,
      AllAttrEnum.hasIgaDeficency,
      AllAttrEnum.hasLongTermProtonPumpInhibiterUse,
      AllAttrEnum.hasEsophogealCancer,
      AllAttrEnum.hasHiatalHernia,
      AllAttrEnum.hasAtrophicGastritis,
      AllAttrEnum.hasPancreaticDisease,
      AllAttrEnum.hasLiverDisease,
      AllAttrEnum.hasCholecystectomy,
      AllAttrEnum.everBoneFractureOrOsteoporosis,
      AllAttrEnum.hasThyroidDisease,
      AllAttrEnum.hasFamilyHistoryOfCeliac,
      AllAttrEnum.hasFamilyHistoryOfGiCancer,
      AllAttrEnum.hasHistoryOfAnemia,
      AllAttrEnum.hasPalpableMassOnAbdomen,
      AllAttrEnum.hasStructuralIssueStomachOrEsophagus,
    ])

  }


  function isValidAnswersThruStepNum(stepNumberAsNumber: number): boolean {
    LOG.debug(`isValidAnswersThruStepNum() - Entering with stepNumberAsNumber = ${stepNumberAsNumber} (${typeof stepNumberAsNumber})`)
    switch (currentStepNumber) {
      case 1:
        return isValidAnswersThruStepNum1()
      case 2:
        return isValidAnswersThruStepNum1()
      case 3:
        return isValidAnswersThruStepNum1()
      case 4:
        return isValidAnswersThruStepNum1()
      case 5:
        return isValidAnswersThruStepNum1()
      case 6:
        return isValidAnswersThruStepNum1()
      case 7:
        return isValidAnswersThruStepNum1()
    }
    return false
  }

  function confirmValidAnswersThruStepNum(stepNumberAsNumber: number): void {
    LOG.debug(`confirmValidAnswersThruStepNum() - Entering with stepNumberAsNumber = ${stepNumberAsNumber} (${typeof stepNumberAsNumber})`)
    if (!isValidAnswersThruStepNum(stepNumberAsNumber)) {
      throw new IncompleteAnswersException()
    }
    // currently no-op

    // if (stepNumberAsNumber >= 99999999) {
    //   if (isUnsetNumber(currentAgeInYears) || isUnsetNumber(currentWeightInLbs) || isUnsetNumber(currentHeightInInches)) {
    //     // expected these to all be set before leaving page one.  But at least one of them one of them is unset
    //     throw new IncompleteAnswersException()
    //   }    
    // } 
    // if (stepNumberAsNumber >= 2) {
    //   LOG.debug(`confirmValidAnswersThruStepNum() - currentDiagnosticAnswersCanIUseThis = ${JSON.stringify(currentDiagnosticAnswersCanIUseThis)}`)
    //   if (isUnsetEverQuestion(currentDiagnosticAnswersCanIUseThis['everNausea']) 
    //       || isUnsetEverQuestion(currentDiagnosticAnswersCanIUseThis['everVomiting']) 
    //       || isUnsetEverQuestion(currentDiagnosticAnswersCanIUseThis['everAbdominalPain'])) {
    //     // expected these to all be set before leaving page one.  But at least one of them one of them is unset
    //     throw new IncompleteAnswersException()
    //   }    
    // }    
  }


  const submittedThisTime = (event: React.ChangeEvent<HTMLInputElement>): void => {
    LOG.debug(`submittedThisTime() - Entering with event = ${safeStringify(event)}`)
    
    // Toggle state to force change even if already submitted
    dispatch(setQuestionnaireSubmittedFlag(false))
    dispatch(setQuestionnaireSubmittedFlag(true))

  }

  function handleContinueClick(event: any, specificPageNumber: number | undefined) {
    event.preventDefault();

    let preventContinueFlag: boolean = false
    try {
      confirmValidAnswersThruStepNum(currentStepNumber)
    } catch (err: any) {
      preventContinueFlag = true;
    }
    LOG.debug(`handleContinueClick() - preventContinueFlag = ${(preventContinueFlag)},  isSubmitVsSave() = ${isSubmitVsSave}`)


    if (preventContinueFlag) {
      // expected these to all be set before leaving page one.  But at least one of them one of them is unset
      // throw new Error("Please Choose")
      sbNotificationPublisher.fireAndForgetErrorByCode(SbNotificationCode.ERROR_PLEASE_CHOOSE)
    } else {
      if (isSubmitVsSave()) {
        // State of questionnaire has been saving all along but this time is different.  It's an official submit
        submittedThisTime(event)
        onDiagnosticAnswersChanged()

        submitInProgressFlag = true;
        
        setTimeout(() => {
          // navigate(`/afterQuestionnaire`, { replace: true });
          navigate(`/findings`, { replace: true });
        }, 2000)
      } else {
        if (specificPageNumber != null) {
          navigate(`/questionnaire?step=${specificPageNumber}`, { replace: true });
        } else {
          navigate(`/questionnaire?step=${currentStepNumber + 1}`, { replace: true });
        }
      }
    }

    return false;
  }

  function handleSubmitAnswers(event: any) {
    handleContinueClick(event, undefined)
  }

  function allowedToClickContinueAtStep1(): boolean | undefined {
    if (developerMode === true) { /* In developer mode you can always click continue */ return true }

    if (currentStepNumber === 1) {
      if (isUnsetNumber(currentAgeInYears)
        || isUnsetNumber(currentWeightInLbs)
        || isUnsetNumber(currentHeightInInches)) {
        // expected these to all be set before leaving page one.  But at least one of them one of them is unset
        return false
      }
      return true
    }
    return undefined
  }

  function allowedToClickContinueAtStep2(): boolean | undefined {
    if (developerMode === true) { /* In developer mode you can always click continue */ return true }

    if (currentStepNumber === 2) {
      return isValidAnswersThruStepNum2()
    }
  }

  function allowedToClickContinueAtStep3(): boolean | undefined {
    if (developerMode === true) { /* In developer mode you can always click continue */ return true }

    if (currentStepNumber === 3) {
      return isValidAnswersThruStepNum3()
    }
  }

  function allowedToClickContinueAtStep4(): boolean | undefined {
    if (developerMode === true) { /* In developer mode you can always click continue */ return true }

    if (currentStepNumber === 4) {
      return isValidAnswersThruStepNum4()
    }
  }

  function allowedToClickContinueAtStep5(): boolean | undefined {
    if (developerMode === true) { /* In developer mode you can always click continue */ return true }

    if (currentStepNumber === 5) {
      return isValidAnswersThruStepNum5()
    }
  }

  function allowedToClickContinueAtStep6(): boolean | undefined {
    if (developerMode === true) { /* In developer mode you can always click continue */ return true }

    if (currentStepNumber === 6) {
      return isValidAnswersThruStepNum6()
    }
  }

  function allowedToClickContinueAtStep7(): boolean | undefined {
    if (developerMode === true) { /* In developer mode you can always click continue */ return true }

    if (currentStepNumber === 7) {
      return isValidAnswersThruStepNum7()
    }
  }


  function allowedToClickContinueAtMyCurrentStep(): boolean {
    LOG.debug(`canClickContinue() - Entering with currentStepNumber = ${currentStepNumber} (${typeof currentStepNumber})`)
    if (developerMode === true) {
      // If you're in developer mode you can always click continue
      return true
    }

    if (currentStepNumber === 1) {
      const authoritativeAnswer = allowedToClickContinueAtStep1()
      if (isOneOfStrictlyTrueOrStrictlyFalse(authoritativeAnswer)) {
        return authoritativeAnswer!
      }
    }

    else if (currentStepNumber === 2) {
      const authoritativeAnswer = allowedToClickContinueAtStep2()
      if (isOneOfStrictlyTrueOrStrictlyFalse(authoritativeAnswer)) {
        return authoritativeAnswer!
      }
    }

    else if (currentStepNumber === 3) {
      const authoritativeAnswer = allowedToClickContinueAtStep3()
      if (isOneOfStrictlyTrueOrStrictlyFalse(authoritativeAnswer)) {
        return authoritativeAnswer!
      }
    }

    else if (currentStepNumber === 4) {
      const authoritativeAnswer = allowedToClickContinueAtStep4()
      if (isOneOfStrictlyTrueOrStrictlyFalse(authoritativeAnswer)) {
        return authoritativeAnswer!
      }
    }

    else if (currentStepNumber === 5) {
      const authoritativeAnswer = allowedToClickContinueAtStep5()
      if (isOneOfStrictlyTrueOrStrictlyFalse(authoritativeAnswer)) {
        return authoritativeAnswer!
      }
    }

    else if (currentStepNumber === 6) {
      const authoritativeAnswer = allowedToClickContinueAtStep6()
      if (isOneOfStrictlyTrueOrStrictlyFalse(authoritativeAnswer)) {
        return authoritativeAnswer!
      }
    }

    else if (currentStepNumber === 7) {
      const authoritativeAnswer = allowedToClickContinueAtStep7()
      if (isOneOfStrictlyTrueOrStrictlyFalse(authoritativeAnswer)) {
        return authoritativeAnswer!
      }
    }

    // if ( currentStepNumber === 1 ){
    //   if (isUnsetNumber(currentAgeInYears) 
    //       || isUnsetNumber(currentWeightInLbs) 
    //       || isUnsetNumber(currentHeightInInches)) {
    //     // expected these to all be set before leaving page one.  But at least one of them one of them is unset
    //     return false
    //   }
    //   return true
    // } 



    // TODO: Toggle this
    return true
  }


  function canSubmitAnswers(): boolean {
    return allowedToClickContinueAtMyCurrentStep()
  }

  const realtimeQuestionnaireSubmittedFlag: boolean | undefined = useSelector((state: SbhcCompositeState) => {
    return state?.questionnaire?.questionnaireSubmittedFlag ?? undefined
  })

  const realtimeHasOrHadAbdominalPain: boolean = useSelector((state: SbhcCompositeState) => {
    return hasOrHadAbdominalPain(state.questionnaire)
  })

  function onClickNoneOfHasConstipationOrHasDiarrheaOrHasSteatorrhea(event: any) {
    event.preventDefault();
    dispatch(setYesOrNo({ eventValue: YesOrNoCodeEnum.NO, attrName: 'hasConstipation' }))
    dispatch(setYesOrNo({ eventValue: YesOrNoCodeEnum.NO, attrName: 'hasDiarrhea' }))
    dispatch(setYesOrNo({ eventValue: YesOrNoCodeEnum.NO, attrName: 'hasSteatorrhea' }))
    onDiagnosticAnswersChanged()
  }

  const onToggleDeveloperMode = async (event: React.ChangeEvent<HTMLInputElement> | any): Promise<void> => {
    LOG.debug(`onToggleDeveloperMode() - Entering with event.target.value = ${event.target.value} (${typeof event.target.value}),   event.target.checked = ${event.target.checked} (${typeof event.target.checked}),  event = ${safeStringify(event)}`, event)

    if (developerMode) {
      updateDeveloperMode(false)
    } else {
      updateDeveloperMode(true)
    }
  }

  return (
    <>
      <header className="page-header">
        <div className="inner">
          <img src="pain.svg" alt="" />
          <div className="text-block">
            <h1>Tell us where it hurts.</h1>
            <p>Diagnosing digestive issues is difficult, and getting an accurate diagnosis can take months if not years. Take your first step with our gut health questionnaire.</p>
          </div>
        </div>
      </header>

      <form>
        <section className="content-block-container">
          <div className="questionnaire-content">
            <div>
              {/* {realtimeQuestionnaireSubmittedFlag === true && <>Your questionnaire <b>has been submitted</b></>}
                {realtimeQuestionnaireSubmittedFlag === false && <>Your questionnaire is in-progress</>}
                {realtimeQuestionnaireSubmittedFlag == null && <>The submission status of your questionnaire is unknown</>} */}
            </div>
            {developerMode &&
              <div className="mt-2">
                <div className="card">
                  <div className="card-header developer-mode">
                    Danger Will Robinson
                  </div>
                  <div className="card-body">
                    <i>Reload your browser after clicking any of these buttons</i><br /><br />
                    <span>(Sometimes you have to Click button, reload-browser, click same button again)</span><br /><br />

                    {/* <button className="btn btn-developer-mode btn-sm" onClick={(event) => { event.preventDefault(); onClearMyFeedbackDangerWillRobinson(event);  return false;}}>Clear Hypothesis Feedback</button>
                &nbsp;
                <button className="btn btn-developer-mode btn-sm" onClick={(event) => { event.preventDefault(); onUnsubmitDangerWillRobinson(event);  return false;}}>Unsubmit</button>
                &nbsp; */}
                    <button className="btn btn-developer-mode btn-sm" onClick={(event) => { event.preventDefault(); onUnsubmitAndClearHypothesesDangerWillRobinson(event); return false; }}>Unsubmit &amp; Clear Hypotheses</button>
                    &nbsp;
                    <button className="btn btn-developer-mode btn-sm" onClick={(event) => { event.preventDefault(); onClearMyQuestionnaireDangerWillRobinson(event); return false; }}>Clear Questionnaire Answers</button>
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" id="devModeToggleSwitch" checked={developerMode} onChange={async (event) => await onToggleDeveloperMode(event)} />
                      <label className="form-check-label" htmlFor="devModeToggleSwitch">Developer Mode</label>
                    </div>
                  </div>
                </div>
              </div>
            }
            <IntakeQuestionnaireProgressComponent stepNumber={stepNumberAsNumber} />

            {stepNumberAsNumber === 1 &&
              <div className="card" id="basic_info">
                <header>
                  <img src="Basic-Info.svg" alt="" />
                  <h2>Basic Info</h2>
                </header>
                <div className="inner">
                  <>
                    <fieldset>
                      <label htmlFor="heightInInches">Height</label>
                      <div style={{ width: '300px' }}>
                        <FeetAndInchesComponent attrName="heightInInches" label="Height" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                      </div>
                    </fieldset>
                    <fieldset>
                      <label htmlFor='weightInLbs'>Weight (in lbs.)</label>
                      <input
                        type='number' max={999} name='weightInLbs' id='weightInLbs' value={currentWeightInLbs ?? ''} onChange={(event) => onChangeWeightInLbs(event)} />
                    </fieldset>

                    <fieldset>
                      <label htmlFor="ageInYears">Age</label>
                      <input
                        type='number' max={100} name='ageInYears' id='ageInYears' value={currentAgeInYears ?? ''} onChange={async (event: React.ChangeEvent<HTMLInputElement>) => await onChangeAgeInYears(event)} />
                    </fieldset>
                  </>
                </div>
              </div>
            }
            {stepNumberAsNumber === 2 &&
              <div className="card" id="digestive_tract">
                <header>
                  <img src="Digestive-Tract.svg" alt="" />
                  <h2>Digestive Tract</h2>
                </header>
                <div className="inner">
                  <CurrentPreviousNeverQuestionComponent attrName="everNausea" label="Nausea" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <CurrentPreviousNeverQuestionComponent attrName="everVomiting" label="Vomiting" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <CurrentPreviousNeverQuestionComponent attrName="everAbdominalPain" label="Abdominal Pain" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <ConditionalNestedQuestionComponent conditional={realtimeIs6CurrentOrPrevious} useContainer={false}>
                    {/* <!-- This is question #200 --> */}
                    <OneToSixPlusMonthsQuestionComponent attrName="abdominalPainHowLong" label="How long have you been experiencing abdominal pain?" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />

                    <UpToThreeTimePerWeekQuestionComponent attrName="abdominalPainHowOften" label="How often are you experiencing abdominal pain?" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                    <UpperLowerBothQuestionComponent attrName="abdominalPainWhere" label="In what part of your abdomen is your pain?" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                    {/* <AlwaysSometimesNeverQuestionComponent attrName="asnRelatedToBowelMovement" label="Are your symptoms related to defecation?" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged}/>
                  <AlwaysSometimesNeverQuestionComponent attrName="asnRelievedByBowelMovement" label="Are your symptoms relieved by defecation?" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged}/> */}
                    <AlwaysSometimesNeverQuestionComponent attrName="asnRelievedOrRelatedToDefecation" label="Are your symptoms relieved or improved after having a bowel movement?" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  </ConditionalNestedQuestionComponent>


                  <CurrentPreviousNeverQuestionComponent attrName="everBloating" label="Bloating" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <CurrentPreviousNeverQuestionComponent attrName="everBurping" label="Burping" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <CurrentPreviousNeverQuestionComponent attrName="everDyspepsia" label="Dyspepsia (indigestion)" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <CurrentPreviousNeverQuestionComponent attrName="everFlatulence" label="Flatulence" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <CurrentPreviousNeverQuestionComponent attrName="everAbdominalDistension" label="Abdominal Distension" tooltipText="An abdomen that is abnormally swollen outward" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                </div>
              </div>
            }

            {stepNumberAsNumber === 3 &&
              <div className="card" id="step-3">
                <header>
                  <img src="Digestive-Tract.svg" alt="" />
                  <h2>Digestive Tract (cont)</h2>
                </header>
                <div className="inner">
                  <YesOrNoQuestionComponent attrName="hasDifficultyOrPainSwallowing" label="Difficulty or Pain Swallowing" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <CurrentPreviousNeverQuestionComponent attrName="everRegurgitation" label="Regurgitation (spitting up of food)" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <CurrentPreviousNeverQuestionComponent attrName="everSourOrBitterTaste" label="Sour or bitter taste in mouth" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <CurrentPreviousNeverQuestionComponent attrName="everFrequentCoughing" label="Frequent coughing" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <CurrentPreviousNeverQuestionComponent attrName="everSwollenAndOrOrDiscoloredTongue" label="Bright red, swollen tongue " onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <CurrentPreviousNeverQuestionComponent attrName="everFrequentNeedToClearThroat" label="Frequent need to clear throat " onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <CurrentPreviousNeverQuestionComponent attrName="everCankerSores" label="Canker Sores" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <CurrentPreviousNeverQuestionComponent attrName="everExcessiveMucus" label="Excessive mucus" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <CurrentPreviousNeverQuestionComponent attrName="everHorseness" label="Hoarseness" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <CurrentPreviousNeverQuestionComponent attrName="everTonguePain" label="Tongue Pain" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <CurrentPreviousNeverQuestionComponent attrName="everChestPain" label="Chest Pain" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />

                  <YesOrNoQuestionComponent attrName="hasEpigastricPainOrBurning" label="Epigastric pain or epigastric burning?" tooltipText="Pain or burning below the ribs" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <ConditionalNestedQuestionComponent conditional={realtimeHasEpigastricPainOrBurning} useContainer={false}>
                    <OneToSixPlusMonthsQuestionComponent attrName="epigastricPainOrBurningHowLong" label="Length of symptoms" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  </ConditionalNestedQuestionComponent>
                  <div className={currentIsYesTo6b_68_105_107_49_or_108 ? undefined : cssConstant.DISPLAY_NONE}>
                    <div className="card mt-4">
                      <div className="card-header">
                        <h5 className="card-title">Do any of your symptoms worsen with any of the following?</h5>
                        <h6 className="card-subtitle mb-2 text-muted">Check all that apply</h6>
                      </div>
                      <div className="card-body">
                        <div className="card-text">
                          <YesOrNoQuestionComponent attrName="hasWorseLargeMeals" label="Large meals?" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                          <YesOrNoQuestionComponent attrName="hasWorseLayingDown" label="Laying down?" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                          <YesOrNoQuestionComponent attrName="hasWorseAcidic" label="Acidic foods?" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                        </div>
                      </div>
                    </div>
                    <div className="card mt-4 mb-4">
                      <div className="card-header">
                        <h5 className="card-title">Do any of your symptoms improve with any of the following?</h5>
                        <h6 className="card-subtitle mb-2 text-muted">Check all that apply</h6>
                      </div>
                      <div className="card-body">
                        <div className="card-text">
                          <YesOrNoQuestionComponent attrName="hasReliefTums" label="Taking Tums?" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                          <YesOrNoQuestionComponent attrName="hasReliefTolaids" label="Taking Rolaids?" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                          <YesOrNoQuestionComponent attrName="hasReliefMylanta" label="Taking Mylanta?" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                          <YesOrNoQuestionComponent attrName="hasReliefH2Blocker" label="Taking an H2 Blocker?" tooltipText="E.g. Pepcid or Zantac" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                          <YesOrNoQuestionComponent attrName="hasReliefProtonPumpInhibitor" label="Taking a Proton pump inhibitor?" tooltipText="E.g., Prevacid, Prilosec, Nexium" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            }

            {stepNumberAsNumber === 4 &&
              <div className="card" id="step-4">
                <header>
                  <img src="Bowel-Habits.svg" alt="" />
                  <h2>Bowel habits</h2>
                </header>
                <div className="inner">
                  <fieldset>
                    <label htmlFor="These 3">Bowel Habits: Check all that apply</label>
                  </fieldset>
                  <ConditionalNestedQuestionComponent conditional={true} useContainer={true}>
                    <YesOrNoQuestionComponent attrName="hasConstipation" label="Constipation" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                    <YesOrNoQuestionComponent attrName="hasDiarrhea" label="Diarrhea" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                    <YesOrNoQuestionComponent attrName="hasSteatorrhea" label="Steatorrhea (excretion of fat with feces)" tooltipText="Seeing your poop look oily or greasy/oil droplets around the bowl" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  
                    <div className="check-all-that-apply-question-component">
                      <fieldset style={{ marginLeft: "20px" }}>
                        <label>
                          <input type='checkbox' onClick={(event) => onClickNoneOfHasConstipationOrHasDiarrheaOrHasSteatorrhea(event)} />
                          <span>
                            <span>&nbsp;None of the above</span>
                          </span>
                        </label>
                      </fieldset>
  {/* 
                      <ConditionalNestedQuestionComponent conditional={realtimeHasConstipationOrHasDiarrheaOrHasSteatorrhea} useContainer={false}>
                        <UpToThreeTimePerWeekQuestionComponent attrName="bowelTroubleHowOften" label="How many times a week do you experience bowel trouble?" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                      </ConditionalNestedQuestionComponent> */}
                    </div>

                    <div className={realtimeHasConstipationOrHasDiarrheaOrHasSteatorrhea ? undefined : cssConstant.DISPLAY_NONE}>
                      <OneToThreeXDailyMoreOrLessQuestionComponent attrName="bowelMovementHowOften" label="How often do you have a bowel movement?" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                      <YesOrNoQuestionComponent attrName="hasNarrowingStoolLast6Months" label="Within the last 6 months has the form (appearance) of your stools changed?" tooltipText="Changes in form can include hard, lumpy stools, soft blobs, mushy stools, narrow pencil-thin stools, or stools with liquid consistency" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                    </div>

                    <div className={realtimeHasConstipationOrHasDiarrheaOrHasSteatorrhea ? undefined : cssConstant.DISPLAY_NONE}>
                      <YesOrNoQuestionComponent attrName="hasBowelMovementFrequencyChangedLast6Months" label="Has the frequency of your bowel movements changed in the last 6 months?" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                    </div>

                    <div className={(realtimeHasBowelMovementFrequencyChangedLast6Months && realtimeHasConstipationOrHasDiarrheaOrHasSteatorrhea) ? undefined : cssConstant.DISPLAY_NONE}>
                      <div className={realtimeHasNarrowingStoolLast6Months ? undefined : cssConstant.DISPLAY_NONE}>
                        <PoopTypeNumberQuestionComponent onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                      </div>
                    </div>


                    <div className={realtimeHasDiarrheaOrHasSteatorrhea ? undefined : cssConstant.DISPLAY_NONE}>
                      <YesOrNoQuestionComponent attrName="hasMidnightDiarrhea" label="Does diarrhea ever wake you up in the middle of the night?" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                    </div>

                    <div className={realtimeHasConstipation ? undefined : cssConstant.DISPLAY_NONE}>
                      <YesOrNoQuestionComponent attrName="hasStrainingBowelMovement" label="Do you experience straining while having a bowel movement?" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                      <YesOrNoQuestionComponent attrName="hasHardOrLumpyStools" label="Are your stools hard or lumpy?" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                      <YesOrNoQuestionComponent attrName="hasSensationIncompleteBowelMovement" label="Do you experience a sensation of incomplete bowel movement?" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                      <YesOrNoQuestionComponent attrName="hasSensationOfAnorectalBlockageOrObstruction" label="Do you experience a sensation of anorectal blockage or obstruction?" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                      <YesOrNoQuestionComponent attrName="hasFewerThan3SpontaneousBowelMovementsWeekly" label="Do you experience fewer than 3 spontaneous bowel movements without the aid of medications per week?" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                      <FrequentSometimesNeverQuestionComponent attrName="fsrLooseStools" label="Do you ever experience loose stools?" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />

                      <YesOrNoQuestionComponent attrName="hasManeuversToAchieveBowelMovement" label="Do you use manual manuevers to achieve a bowel movement?" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                    </div>
                  </ConditionalNestedQuestionComponent>

                  <YesOrNoQuestionComponent attrName="hasCurrentlyBloodInStool" label="Are you currently experiencing blood in your stools?" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                </div>
              </div>
            }

            {stepNumberAsNumber === 5 &&
              <div className="card" id="step-weight-food">
                <header>
                  <img src="Weight-Food.svg" alt="" />
                  <h2>Weight / Food</h2>
                </header>
                <div className="inner">
                  <CurrentPreviousNeverQuestionComponent attrName="everUnexpectedWeightLoss10In3" label="Sudden, Unexpected Weight Loss" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} tooltipText="Over 7.5% of your body weight in 3 months " />
                  <CurrentPreviousNeverQuestionComponent attrName="everExcessiveWeightGain" label="Excessive Weight Gain" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <CurrentPreviousNeverQuestionComponent attrName="everLossOfAppetite" label="Loss of Appetite" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <CurrentPreviousNeverQuestionComponent attrName="everWaterRetention" label="Water Retention" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <YesOrNoQuestionComponent attrName="hasSymptomsWorsenWithGlutenIngestion" label="Do your symptoms worsen with gluten ingestion?" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <YesOrNoQuestionComponent attrName="hasEarlyFullnessDuringMeal" label="Do you experience early fullness during a meal?" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <ConditionalNestedQuestionComponent conditional={realtimeHasEarlyFullnessDuringMeal} useContainer={false}>
                    <OneToSixPlusMonthsQuestionComponent attrName="earlyFullnessSymptomsHowLong" label="Length of symptoms" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  </ConditionalNestedQuestionComponent>

                </div>
              </div>
            }

            {stepNumberAsNumber === 6 &&
              <div className="card" id="step-emotional-well-being">
                <header>
                  <img src="Emotional-Well-being.svg" alt="" />
                  <h2>Emotional Well-being</h2>
                </header>
                <div className="inner">
                  <OneToTenQuestionComponent attrName="stressLevel1To10" label="Stress Level (1-10)" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <OneToTenQuestionComponent attrName="anxietyLevel1To10" label="Anxiety Level (1-10)" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <CurrentPreviousNeverQuestionComponent attrName="everSluggishness" label="Fatigue / Sluggishness" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  {/* <CurrentPreviousNeverQuestionComponent attrName="everRestlessness" label="Restlessness" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged}/> */}
                  <CurrentPreviousNeverQuestionComponent attrName="everBrainFog" label="Brain Fog" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  {/* <CurrentPreviousNeverQuestionComponent attrName="everSleepDisturbances" label="Sleep Disturbances" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged}/> */}
                </div>
              </div>
            }



            {stepNumberAsNumber === 7 &&
              <div className="card" id="step-history">
                <header>
                  <img src="Health-History.svg" alt="" />
                  <h2>Health History</h2>
                </header>
                <div className="inner">
                  {/* <YesOrNoQuestionComponent attrName="hasHypertension" label="Hypertension" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged}/> */}
                  <YesOrNoQuestionComponent attrName="hasHyperlipidemia" label="Hyperlipidemia" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <YesOrNoQuestionComponent attrName="hasType2Diabetes" label="Type-2 Diabetes" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <YesOrNoQuestionComponent attrName="hasType1Diabetes" label="Type-1 Diabetes" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <YesOrNoQuestionComponent attrName="hasOpiodUse" label="Opioid Use" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <YesOrNoQuestionComponent attrName="hasBowelObstruction" label="Bowel obstruction" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <YesOrNoQuestionComponent attrName="hasObesity" label="Obesity" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <YesOrNoQuestionComponent attrName="hasRadiationToIntestines" label="Radiation to intestines" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <YesOrNoQuestionComponent attrName="hasTerminalIlealResection" label="Terminal Ileal Resection" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <YesOrNoQuestionComponent attrName="hasIleocecalValveResection" label="Ileocecal Valve Resection" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  {/* <YesOrNoQuestionComponent attrName="hasAmyloidosis" label="Amyloidosis" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged}/>
                <YesOrNoQuestionComponent attrName="hasScleroderma" label="Scleroderma" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged}/> */}
                  <YesOrNoQuestionComponent attrName="hasBowelStrictureOrAdhesions" label="Bowel Stricture or Adhesions" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <YesOrNoQuestionComponent attrName="hasSmallBowelDiverticulosis" label="Small Bowel Diverticulosis" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <YesOrNoQuestionComponent attrName="hasAbdominalSurgeryHistory" label="Abdominal Surgery History" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <YesOrNoQuestionComponent attrName="hasIleocectomyHistory" label="Ileocecectomy History" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  {/* <YesOrNoQuestionComponent attrName="hasCommonVariableImmunodeficiency" label="Common Variable Immunodeficiency" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged}/> */}
                  <YesOrNoQuestionComponent attrName="hasIgaDeficency" label="IgA Deficiency" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  {/* <YesOrNoQuestionComponent attrName="hasHivOrAids" label="HIV or AIDS" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged}/> */}
                  <YesOrNoQuestionComponent attrName="hasLongTermProtonPumpInhibiterUse" label="Long Term Proton Pump Inhibitor Use" tooltipText="More than 6 months" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />

                  <YesOrNoQuestionComponent attrName="hasEsophogealCancer" label="Esophogeal Cancer" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <YesOrNoQuestionComponent attrName="hasHiatalHernia" label="Hiatal Hernia" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />

                  <YesOrNoQuestionComponent attrName="hasAtrophicGastritis" label="Atrophic Gastritis" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <YesOrNoQuestionComponent attrName="hasPancreaticDisease" label="Pancreatic Disease" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <YesOrNoQuestionComponent attrName="hasLiverDisease" label="Liver Disease" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <YesOrNoQuestionComponent attrName="hasCholecystectomy" label="Cholecystectomy" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />

                  <CurrentPreviousNeverQuestionComponent attrName="everBoneFractureOrOsteoporosis" label="Bone Fracture or Osteoporosis" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <YesOrNoQuestionComponent attrName="hasThyroidDisease" label="Thyroid Disease" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <YesOrNoQuestionComponent attrName="hasFamilyHistoryOfCeliac" label="Family history of Celiac" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <YesOrNoQuestionComponent attrName="hasFamilyHistoryOfGiCancer" label="Family history of Upper GI cancer" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <YesOrNoQuestionComponent attrName="hasHistoryOfAnemia" label="History of anemia" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <YesOrNoQuestionComponent attrName="hasPalpableMassOnAbdomen" label="Palpable mass on abdomen" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                  <YesOrNoQuestionComponent attrName="hasStructuralIssueStomachOrEsophagus" label="Structural issue in stomach or esophagus" onDiagnosticAnswersChanged={onDiagnosticAnswersChanged} />
                </div>
              </div>
            }

            <div className="row">
              <div className="col-6">
                <div style={{ marginTop: '10px' }}>
                  {(stepNumberAsNumber - 1) > 0 &&
                    <Link className="btn" to={`/questionnaire?step=${stepNumberAsNumber - 1}`}>Back to Step #{stepNumberAsNumber - 1}</Link>
                  }
                </div>
              </div>
              <div className="col-6">
                <div style={{ marginTop: '10px', textAlign: 'right' }}>
                  {developerMode && currentStepNumber != TOTAL_NUM_STEPS &&
                    <><button className="btn btn-developer-mode" disabled={!allowedToClickContinueAtMyCurrentStep()} onClick={(event) => handleContinueClick(event, TOTAL_NUM_STEPS)}>Jump to Step #{TOTAL_NUM_STEPS}</button>&nbsp;</>
                  }
                  {isSubmitVsSave()
                    ? <button className="btn btn-primary btn-monique" disabled={!canSubmitAnswers()} onClick={handleSubmitAnswers}>Submit your answers</button>
                    : <button className="btn btn-primary btn-monique" disabled={!allowedToClickContinueAtMyCurrentStep()} onClick={(event) => handleContinueClick(event, undefined)}>Continue to Step #{stepNumberAsNumber + 1}</button>
                  }&nbsp;
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </>
  )
}