import { ClearMyUserAttributesResponse, ExistingUserAttributesResponse } from "../commonSrc/constant/type";
import { UserAttributeFetchingService } from "./UserAttributeFetchingService";
import { getLogger } from '../log/getLogger'
import { injectable } from "inversify";
import { WhoAmISvc } from "../auth/WhoAmISvc";
import diContainer from '../inversify.config'
import DI_TYPES from "../diTypes";
import safeStringify from "../string/safeStringify";

const LOG = getLogger('user/UserAttributeFetchingService')

@injectable()
export class UserAttributeFetchingServiceImpl implements UserAttributeFetchingService {
  public fetchExistingUserAttributes(): Promise<ExistingUserAttributesResponse> {
    LOG.debug(`fetchExistingUserAttributes(): Entering`)

    const whoAmISvc: WhoAmISvc = diContainer.get<WhoAmISvc>(DI_TYPES.WhoAmISvc)
    const loggedInFlag: boolean = whoAmISvc.amILoggedIn()
    const oauth2Username: string | undefined = whoAmISvc.getMyUsername()
    const oauth2UserId: string | undefined = whoAmISvc.getMyUserId()

    const headers: any = {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
    }

    if (whoAmISvc.amILoggedIn()) {
      LOG.debug(`fetchExistingUserAttributes(): User is signed in.  jwtToken = ${whoAmISvc.getMyAuthToken()}`)
      headers["Authorization"] = `Bearer ${whoAmISvc.defGetMyAuthToken()}`
    } else {
      LOG.warn(`fetchExistingUserAttributes(): Unauthorized 401.  User is NOT signed in.`)
    }

    const requestOptions = {
      method: 'DELETE',
      // mode: 'cors',
      headers: headers
    };

    const url = (process.env.REACT_APP_BACKEND_BASE_URL || 'http://localhost:9000') + `${process.env.REACT_APP_BACKEND_RELATIVE_API_PATH}` + '/userAttributes/latest'

    // Broken pile of junk
    // return fetch(url, requestOptions).then(response => {
    //   const jsonBody = await response.json()
    //   LOG.debug(`fetchExistingUserAttributes() - fetch() returned a response = ${safeStringify(jsonBody)}`, jsonBody)
    //   return jsonBody
    // })
    return fetch(url, requestOptions).then(response => response.json())
  }

  public clearMyUserAttributesDangerWillRobinson(): Promise<ClearMyUserAttributesResponse> {
    LOG.debug(`clearMyUserAttributesDangerWillRobinson(): Entering`)

    const whoAmISvc: WhoAmISvc = diContainer.get<WhoAmISvc>(DI_TYPES.WhoAmISvc)
    // const loggedInFlag: boolean = whoAmISvc.amILoggedIn()
    // const oauth2Username: string | undefined = whoAmISvc.getMyUsername()
    // const oauth2UserId: string | undefined = whoAmISvc.getMyUserId()

    const headers: any = {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache',
    }

    if (whoAmISvc.amILoggedIn()) {
      LOG.debug(`clearMyUserAttributesDangerWillRobinson(): User is signed in.  jwtToken = ${whoAmISvc.getMyAuthToken()}`)
      headers["Authorization"] = `Bearer ${whoAmISvc.defGetMyAuthToken()}`
    } else {
      LOG.warn(`clearMyUserAttributesDangerWillRobinson(): Unauthorized 401.  User is NOT signed in.`)
    }

    const requestOptions = {
      method: 'GET',
      // mode: 'cors',
      headers: headers
    };

    const url = (process.env.REACT_APP_BACKEND_BASE_URL || 'http://localhost:9000') + `${process.env.REACT_APP_BACKEND_RELATIVE_API_PATH}` + '/userAttributes/delete'

    // Broken pile of junk
    // return fetch(url, requestOptions).then(response => {
    //   const jsonBody = await response.json()
    //   LOG.debug(`fetchExistingUserAttributes() - fetch() returned a response = ${safeStringify(jsonBody)}`, jsonBody)
    //   return jsonBody
    // })
    return fetch(url, requestOptions).then(response => response.json())
  }

}